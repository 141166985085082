/* Text Logo Image */
.dark-text-logo {
	width: 380px;
	content: url("../../assets/imgs/dark-text-logo.png");
}
/* Description Header */
.desc-header {
	color: #fdc32d;
	font-size: 3rem;
	font-weight: 800;
	line-height: 1.2;
	padding-bottom: 2rem;
	text-align: center;
}
/* Description Text */
.desc-text {
	text-align: center;
	text-align-last: center;
	font-size: 1.125rem;
	font-weight: 700;
	color: #1b2c49;
	width: 44%;
	line-height: 2;
	letter-spacing: 0;
}
