.main-container {
	display: flex;
	flex-direction: column;
	gap: 5rem;
	margin-bottom: 12rem;
}
/* Common Section Rules */
.section-container {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.section-header {
	color: #1b2c49;
	font-size: 1.5rem;
	font-weight: 800;
}
/* Cards Container */
.cards-container {
	display: flex;
	justify-content: space-between;
	max-width: 950px;
	width: 100%;
	direction: ltr;
	font-weight: 700;
}
.cards-container .card-text {
	width: 82%;
	line-height: 1.3;
}
