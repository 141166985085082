nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 11.5vh;
  background-color: black;
  display: flex;
  justify-content: center;
}
/* Button Container */
.btn-container {
  height: 100%;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
  direction: ltr;
  display: flex;
}
/* Whatsapp Icon Styling */
.nav-whatsapp-icon {
  color: #34ca1c;
  font-size: 3.1rem;
}
/* Subscribe Button Styling */
.sub-btn {
  background-color: #fdc32d;
  border: 0;
  border-radius: 6.25rem;
  color: #1b2c49;
  padding: 0.75rem 3rem;
  font-family: ultranet;
  font-size: 100%;
  font-weight: 700;
  line-height: 1.3;
  transition: 0.25s ease;
}
.sub-btn:hover {
  background-color: #fecf5c;
}
/* Phone Icon Styling */
.nav-phone-icon {
  font-size: 3.1rem;
  color: white;
}
@media screen and (max-width: 768px) {
  .btn-container {
    height: 100%;
    width: 60%;
    justify-content: space-evenly;
    align-items: center;
    direction: ltr;
    display: flex;
  }
}
