/* Main Container */
.section-container#form {
  background-color: #1b3358;
  padding-top: 2rem;
  min-height: 50vh;
  background-image: url("../../assets/imgs/formBackground.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Image Container */
.img-container {
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
}
/* Top Text Image */
.top-motto {
  content: url("../../assets/imgs/text-img1.png");
  width: 375px;
  max-width: 50%;
}
/* Middle Text Image */
.middle-motto {
  content: url("../../assets/imgs/text-img2.png");
  width: 650px;
  max-width: 80%;
  margin-top: 0.5rem;
  margin-right: 3.25rem;
}
/* Bottom Text Image */
.bottom-motto {
  content: url("../../assets/imgs/text-img3.png");
  margin: auto;
  padding-top: 0.75rem;
  padding-left: 4rem;
  max-width: 100%;
}
/* Text Logo */
.text-logo {
  content: url("../../assets/imgs/light-text-logo.png");
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.75rem;
}
/* Contact Info Section */
.contact-info {
  min-height: 13.25rem;
  width: 60rem;
  display: flex;
  direction: ltr;
  padding: 3rem 0;
}
/* Info Card Styling */
.whatsapp-info,
.phone-info,
.email-info {
  color: white;
  flex: 1;
  font-family: "Lato";
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Info Card Text */
.whatsapp-phone-number,
.phone-number,
.email-text {
  font-family: "UltraNet";
  font-weight: 400;
  font-size: 2.3rem;
  direction: ltr;
  margin-top: auto;
}
/* Whatsapp Info */
.whatsapp-icon {
  font-size: 2.5rem;
}
.whatsapp-text {
  font-size: 1.5rem;
}

/* Phone Number Info */
.phone-icon {
  font-size: 3rem;
}
/* Email Info */
.email-icon {
  font-size: 3rem;
}
.email-text {
  font-size: 1.5rem;
  padding-bottom: 0.25rem;
}
@media screen and (max-width: 768px) {
  /* Contact Media Query Styling */
  .contact-info {
    flex-direction: column;
    justify-content: space-between;
  }
  .whatsapp-info,
  .phone-info,
  .email-info {
    margin-top: 2rem;
  }
  .whatsapp-phone-number,
  .phone-number,
  .email-text {
    margin-top: 0;
  }
}
