* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	direction: rtl;
	scroll-behavior: smooth;
	max-width: 100%;
}
a {
	text-decoration: none;
}
body {
	font-family: "UltraNet";
}
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@font-face {
	font-family: "UltraNet";
	src: url("./assets/fonts/ultranet400.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "UltraNet";
	src: url("./assets/fonts/ultranet700.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "UltraNet";
	src: url("./assets/fonts/ultranet800.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
